
@mixin isp-modalContext() {
	@include fadeOut($overlayDuration);
	display: none;
	// min-height: 150vh;
	// padding-top: $headerHeightSmall;
	position: relative;
	top: 0;
	left: 0;
	right: 0;

	@include media($smallLayout) {
		// padding-top: $headerHeightMedium;
	}

	&.isp-js-fixed {
		position: fixed;
	}


	&.isp-js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.isp-js-duringActive {
		@include fadeIn();
	}


	&::before {
		background-color: transparent;
		bottom: 0;
		content: '';
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 0;
	}
}
