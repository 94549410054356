.isp-main {
	visibility: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100%;

	.isp-body.isp-js-loaded & {
		visibility: inherit;
	}

	.isp-body.isp-js-pageReportConfig & {
		display: block;
		height: 100%;
	}

	&__footer {
		position: relative;
		z-index: 2;

		&.isp-js-covered {
			@include isp-covered();
		}

		.isp-body.isp-js-pageReportConfig & {
			display: none;
		}
	}


	&__glossary {
		@include isp-modalContext();
		z-index: 4;
	}


	&__header {
		// non-stacking container, style is defined in the elements inside
	}


	&__mainMenu {
		@include isp-modalContext();
		z-index: 4;
	}


	&__pageContainer {
		position: relative;
		z-index: 1;
		flex-grow: 1;

		&.isp-js-covered {
			@include isp-covered();
		}
	}


	&__search {
		@include isp-modalContext();
		z-index: 3;
	}
}
