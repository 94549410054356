.isp-page {
	// overflow: hidden;
	padding-bottom: $base6;
	position: relative;

	@include media($smallLayout) {
		padding-bottom: $base * 13;
	}

	@include media($largeLayout) {
		padding-bottom: $base * 14;
	}

	&--reportConfig {
		padding-bottom: 0;

		@include media($smallLayout) {
			padding-bottom: 0;
		}

		@include media($largeLayout) {
			padding-bottom: 0;
		}
	}


	&__bgFigure {
		bottom: 0;
		left: 0;
		height: 100vh;
		top: auto;
		right: 0;
		position: fixed;
		z-index: -1;
	}


	&__bgImg {
		@include objectFit(cover, 50% 0%);
		@include fadeInOnLoad(isp-js-loaded);
		height: 100%;
		width: 100%;
	}


	&__blocks {
		position: relative;
		z-index: 1;
	}


	&__menu {
		position: sticky;
		top: 0;
		height: 0;
	}
}
