.isp-loading {
	@include ease(opacity visibility, $duration2);
	background-color: $colorBgBase;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	&.isp-js-beforeHidden {
		will-change: opacity;
	}

	&.isp-js-duringHidden {
		opacity: 0;
		visibility: hidden;
	}

	&.isp-js-hidden {
		display: none;
	}


	&__logo {
		align-items: center;
		color: $colorBase;
		display: flex;
		flex-direction: row;
		height: $base * 3.2;
		text-decoration: none;

		@include media($smallLayout) {
			height: $base * 3.6;
		}

		@include media($largeLayout) {
			height: $base * 4.4;
		}
	}


	&__logoImg {
		height: 100%;
		width: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}


	&__logoType {
		flex-grow: 0;
		flex-shrink: 0;
		height: 100%;
		margin-left: $base;


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}
}
