.isp-body {
	@include font(base);
	background-color: $colorBgBase;
	color: $colorBase;
	font-family: $fontFamilyMain;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	position: fixed;

	&.isp-js-pageHome {
		background-color: $colorBase;
	}

	&.isp-js-loaded {
		position: static;
	}


	&__loading {
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 10;
	}

	&.isp-js-inactive {
		overflow: hidden;
	}
}


