.isp-block {
	@include mediaMap($sizeVariants, blockVerticalSpacing);
	position: relative;
	z-index: 1;

	.isp-page--home & {
		@include mediaMap($sizeVariants, blockVerticalSpacingHome);
	}

	.isp-page:not(.isp-page--home) &--withTitle {
		@include mediaMap($sizeVariants, blockVerticalSpacingWithTitle);
	}

	&--pageMenu + & {
		margin-top: $pageMenuIconSpacing;

		@include media($smallLayout) {
			margin-top: initial;
		}
	}

	&--configurator {
		height: 100%;
		padding-top: $headerHeightSmall;

		@include media($smallLayout) {
			padding-top: $headerHeightMedium;
		}

		@include media($largeLayout) {
			padding-top: $headerHeightMedium + $base2;
		}
	}


	&--cover {
		@include mediaMap($sizeVariants, coverVerticalSpacing);
		@include mediaMap($sizeVariants, blockVerticalSpacing, padding-bottom);
		background-color: $colorBgAlternative;
		color: $colorAlternative;

		.isp-page--home & {
			background-color: transparent;
			@include mediaMap($sizeVariants, coverVerticalSpacingHome);
		}

		.isp-page--glossary & {
			background-color: $colorGlossaryCover;
			color: $colorInverted;
		}

		.isp-glossary__blocks & {
			display: none;
		}
	}


	&--image {
		.isp-block--cover + & {
			padding-top: 0;
		}

		.isp-block--cover + &::before {
			background-color: $colorBgAlternative;
			content: '';
			height: 50%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: -1;
		}
	}

	&--linksReference {
		display: none;
	}

	&--miniConfigurator {
		@include mediaMap($sizeVariants, blockVerticalSpacing, margin-top);
		@include mediaMap($sizeVariants, blockVerticalSpacingWithTitle, padding-bottom);
		background-color: $colorBgAlternative;
		color: $colorAlternative;
	}

	&--pageMenu {
		padding-top: 0;
		position: sticky;
		height: 0;
		// margin-bottom: $base2;
		top: $headerHeightSmall;
		z-index: 4;

		&:not(.isp-block--pageMenuSearch) {
			@include isp-container();
		}

		&.isp-js-covered {
			@include isp-covered();
			right: 0;
		}

		@include media($smallLayout) {
			top: $headerHeightMedium;
			padding-top: 0;
			height: 0;
		}

		@include media($largeLayout) {
			padding-top: 0;
		}
	}

	&--rankingMap {
		z-index: 3;
	}


	&--featuredStory {
		z-index: -1;
	}

	&--full {
		z-index: 3;
	}

	&--glossaryList {
		padding-top: $base2;
	}


	&--plot {
		overflow: hidden;
	}

	.isp-page--withBg & {
		color: $colorInverted;
	}


	&__content {
		@include isp-container();
		position: relative;

		.isp-block__title + & {
			margin-top: $base2;

			@include media($smallLayout) {
				margin-top: $base4;
			}
		}
	}


	&__text {
		@include isp-subContainer();
	}


	&__title {
		@include isp-container();
		@include font(h2);
		position: relative;
		width: 100%;
		z-index: 1;

		.isp-page--home & {
			text-align: center;
		}
	}


	&__titleContent {
		.isp-page:not(.isp-page--home) & {
			@include isp-subContainer();
		}

		.isp-page:not(.isp-page--home) .isp-block:not(.isp-block--cover):not(.isp-block--pageMenu) + .isp-block:not(.isp-block--miniConfigurator) & {
			border-top: 1px solid currentColor;
			padding-top: $base5;

			@include media($smallLayout) {
				padding-top: $base8;
			}
		}
	}
}
