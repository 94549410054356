.isp-cover {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	position: relative;


	&__bg {
		@include mediaMap($sizeVariants, reportCoverMap);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}


	&__bgImg {
		@include objectFit(contain, 50% 0);
		@include fadeInOnLoad(isp-js-loaded);
		position: relative;
		width: 100%;
		height: 100%;
	}


	&__callToAction {
		.isp-page--home & {
			@include media($smallLayout) {
				transform: translateY(-50%);
			}
		}
	}


	&__category {
		@include font(base);
		@include isp-subContainer();
		color: $colorCoverCategory;
		order: 1;
	}


	&__extra {
		display: block;
		position: relative;
		text-align: center;
		order: 5;

		* + & {
			margin-top: $base3;
		}

		.isp-page--home * + & {
			@include media($smallLayout) {
				margin-top: 0;
			}
		}

		.isp-page--home & {
			@include media($smallLayout) {
				height: 0;
				width: auto;
				margin-left: staticColumnPush(7, 12, $gutter);
				order: 3;
			}
		}

		&--report {
			@include media($smallLayout) {
				margin-left: staticColumnPush(2, 12, $gutter);
			}

			@include media($mediumLayout) {
				position: absolute;
				top: 0;
				right: $containerPaddingMedium;
			}

			@include media($largeLayout) {
				right: staticColumnPush(1, 12, $gutter, $containerPaddingLarge);
			}
		}
	}


	&__reportAddIndicators {
		margin-top: $base2;
	}


	&__reportAddIndicatorsLink {
		@include isp-actionLink($colorHighlight);
	}


	&__reportInfo {
		width: 100%;

		@include media($smallLayout) {
			@include staticColumns($vSpace: $base);
			align-items: flex-end;
		}
	}


	&__reportInfoIndicator {
		margin-bottom: $base * 0.75;
		position: relative;
	}


	&__reportInfoIndicatorLink {
		border-bottom: 1px solid transparent;
		color: inherit;
		padding-bottom: $baseHalf / 2;
		text-decoration: none;

		@include onHover() {
			border-bottom-color: $colorHighlight;
		}
	}


	&__reportInfoIndicatorIcon {
		color: $colorConfiguratorAction;
		height: $base * 1.8;
		left: -$base3;
		line-height: 0;
		margin-top: -$base * 0.9;
		position: absolute;
		top: (getCalculatedLineHeight(base) / 2);
		width: $base * 1.8;

		@include media($smallLayout) {
			top: (getCalculatedLineHeight(base, $smallLayout) / 2);
		}

		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__reportInfoIndicators {
		@include ease(height, $duration4);
		overflow: hidden;
		position: relative;
		padding-left: $base3;

		@include media($smallLayout) {
			left: -$base3;
		}
	}


	&__reportInfoIndicatorsList {
		@include ease(opacity visibility, $duration4);
		position: relative;
		top: 0;
		width: 100%;

		&.isp-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}


	&__reportInfoTerm {
		@include font(base);
		color: $colorReportCoverTerm;
		margin-left: 0;

		.isp-cover__reportInfoValue + & {
			margin-top: $base;
		}

		@include media($smallLayout) {
			width: staticColumnSize(2, 12, $gutter);
		}

		@include media($largeLayout) {
			width: staticColumnSize(3, 12, $gutter);
		}


		&--type {
			@include media($smallLayout) {
				// exception, is not fitting in a line for some pixels, designer insisted, so... ¯\_(ツ)_/¯
				// if the label changes we need to address this
				white-space: nowrap;
			}
		}

		&--indicators {
			@include media($smallLayout) {
				margin-left: staticColumnPush(2, 12, $gutter);
			}

			@include media($largeLayout) {
				margin-left: staticColumnPush(3, 12, $gutter);
			}
		}


		&::after {
			content: ':';
		}
	}


	&__reportInfoValue {
		@include font(h2);
		margin-left: 0;

		@include media($smallLayout) {
			width: staticColumnSize(10, 12, $gutter);
		}

		@include media($largeLayout) {
			width: staticColumnSize(9, 12, $gutter);
		}

		&--indicators {
			@include font(base);
			font-weight: 600;
			margin-top: 0;

			@include media($smallLayout) {
				margin-left: staticColumnPush(2, 12, $gutter);
			}

			@include media($largeLayout) {
				margin-left: staticColumnPush(3, 12, $gutter);
			}
		}
	}


	&__reportShowAllAction {
		color: $colorHighlight;
		cursor: pointer;
	}


	&__reportShowAllActionLabel {
		.isp-cover__reportShowAllAction.isp-js-toggled &--expand,
		.isp-cover__reportShowAllAction:not(.isp-js-toggled) &--collapse {
			display: none;
		}
	}



	&__text {
		@include font(important);
		margin-top: $base2;
		order: 4;

		@include media($smallLayout) {
			margin-top: $base4;
			max-width: staticColumnSize(6, 12, $gutter);
		}

		.isp-page:not(.isp-page--home) & {
			@include isp-subContainer();
			@include font(base);
			font-weight: 600;
		}
	}


	&__title {
		@include font(h1);
		order: 2;



		.isp-page:not(.isp-page--home) & {
			@include isp-subContainer();
		}

		.isp-page.isp-page--report & {
			@include media($smallLayout) {
				min-height: $base * 25;
			}

			@include media($mediumLayout) {
				margin-left: staticColumnPush(2, 12, $gutter);
				margin-right: auto;
				max-width: staticColumnSize(7, 12, $gutter);
			}

			@include media($largeLayout) {
				margin-left: staticColumnPush(3, 12, $gutter);
				max-width: staticColumnSize(6, 12, $gutter);
			}
		}

		.isp-page--home & {
			border-bottom: 1px solid currentColor;
			border-top: 1px solid currentColor;
			padding-top: $base2;
			padding-bottom: $base3;

			@include media($smallLayout) {
				max-width: staticColumnSize(8, 12, $gutter);
				padding-top: $base3;
				padding-bottom: $base5;
			}
		}
	}
}
