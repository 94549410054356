.isp-header {
	&::before {
		@include isp-container();
		content: '';
		// background-clip: content-box;
		// MS browsers do not apply the background-clip to bgcolors, only to images,
		// so we emulate the bg color with a gradient
		background-image: linear-gradient($colorBgBase, $colorBgBase);
		height: $headerHeightSmall;
		left: 50%;
		padding-left: 0;
		padding-right: 0;
		position: fixed;
		top: 0;
		transform: translateX(-50%);
		width: 100%;
		z-index: 3;

		@include media($smallLayout) {
			height: $headerHeightMedium;
		}

		@include media($largeLayout) {
			background-clip: content-box;
		}


		.isp-body.isp-js-pageReportConfig & {
			border-bottom: 1px solid $colorDecoration;

			@include media($largeLayout) {
				border-bottom: 0;
			}
		}
	}


	&__container {
		left: 0;
		right: 0;
		top: 0;
		position: fixed;
		z-index: 5;
	}


	&__content {
		@include isp-container();
		height: $headerHeightSmall;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: $containerPaddingSmall;
		padding-right: $containerPaddingSmall;
		position: relative;
		z-index: 5;

		@include media($smallLayout) {
			height: $headerHeightMedium;
			padding-left: $containerPaddingMedium;
			padding-right: $containerPaddingMedium;
		}

		@include media($largeLayout) {
			padding-left: $containerPaddingLarge + $base4;
			padding-right: $containerPaddingLarge + $base4;
		}
	}

	&__logo {
		.isp-body[data-isp-current-context="glossary"] & {
			opacity: 0;
			visibility: hidden;

			@include media($smallLayout) {
				opacity: 1;
				visibility: inherit;
			}
		}
	}


	&__logoImg {
		height: 100%;
		width: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}


	&__logoLink {
		align-items: center;
		color: $colorBase;
		display: flex;
		flex-direction: row;
		height: $base * 3.2;
		text-decoration: none;

		@include media($smallLayout) {
			height: $base * 3.6;
		}

		@include media($largeLayout) {
			height: $base * 4.4;
		}
	}


	&__logoType {
		flex-grow: 0;
		flex-shrink: 0;
		height: 100%;
		margin-left: $base;

		.isp-header.isp-js-covered:not(.isp-js-coveredByPageMenu) & {
			display: none;

			@include media($smallLayout) {
				display: inline-block;
			}
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__nav {
		display: flex;
		flex-direction: row;
		align-items: center;
	}


	&__navItem {
		&:not(:last-child) {
			margin-right: $base3;

			@include media($smallLayout) {
				margin-right: $base5;
			}
		}
	}
}
